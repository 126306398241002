
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































































































































































































.card-small-item,
[class*='card-small-item--'] {
  @extend %box-shadow;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30rem;
  max-width: 40rem;
  margin: 0 0 $spacing * 2.5;
  text-align: center;
  background: $white;

  &:last-child {
    margin-bottom: 0;
  }

  &[class*='--negative'] {
    align-items: flex-start;
    text-align: left;
    background: $c-blue-dark;
  }
}

.card-small-item-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: $spacing * 1.5;

  .blog-archive &,
  .blog-single & {
    text-align: left;
  }

  @include mq($until: m) {
    height: 25rem;
  }

  @include mq(m) {
    padding: $spacing * 1.5;
  }

  @include mq(l) {
    padding: $spacing * 2 $spacing * 1.5;
  }

  @include mq(xl) {
    padding: $spacing * 2;
  }
}

.card-small-item__picture-outer {
  position: relative;
  width: 100%;
  height: 15rem;

  @include mq(m) {
    height: 25rem;
  }

  &.darkened::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: rgba($black, 0.5);
  }
}

.card-small-item__picture {
  @include image-fit;

  background-color: $c-gray-lightest;
}

.card-small-item__bg {
  position: absolute;
  right: -3rem;
  bottom: -3rem;
  height: 70%;
}

.card-small-item__header {
  position: relative;
  flex-shrink: 0;
}

.card-small-item__header__category {
  @extend %box-shadow;
  @extend %text-uppercase;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0.6rem 1.2rem 0.5rem;
  color: $c-anthracite-light;
  font-family: $ff-alt;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: $white;
  border-radius: 0.6rem;
  transform: translateY(-150%);
  white-space: nowrap;
}

.card-small-item__icon {
  width: auto;
  height: 2.5rem;
  fill: $c-pink-medium;

  [class*='card-small-item--'][class*='--negative'] & {
    fill: $white;
  }
}

.card-small-item__date {
  @extend %text-uppercase;
  @extend %fw-medium;

  margin-bottom: 1.2rem;
  color: $c-gray-darkest;
  font-family: $ff-alt;
  font-size: 1.4rem;
}

.card-small-item__title {
  width: 100%;
  margin: 0 0 $spacing * 1.5;
  font-size: 2.2rem;

  .blog-archive &,
  .blog-single & {
    margin-top: $spacing / 2;
  }

  [class*='card-small-item--'][class*='--negative'] & {
    color: $white;
  }

  @include mq(m) {
    font-size: 2.4rem;
  }
}

.card-small-item__video {
  margin: auto #{$spacing * -1.5} #{$spacing * -1.5};

  @include mq(l) {
    margin: auto #{$spacing * -1.5} #{$spacing * -2};
  }

  @include mq(xl) {
    margin: auto #{$spacing * -2} #{$spacing * -3};
  }
}

.card-small-item__text {
  @extend %fw-light;

  width: 100%;
  margin: 0 0 $spacing;
  font-size: 1.6rem;
  line-height: 1.5;

  [class*='card-small-item--'][class*='--negative'] & {
    color: $white;
  }

  @include mq(m) {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.card-small-item__cta,
[class*='card-small-item__cta--'] {
  margin-top: auto;

  .card-small-item:hover & {
    color: $c-blue-dark;
    background-position: 100% 100%, 0 100%;
    background-size: 0 2px, 100% 2px;
  }

  &[class*='--full'] {
    @include get-all-space;
  }
}

.card-small-item__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing * 2;
  padding-top: $spacing;
  border-top: 1px solid $c-gray-lightest;
}

.card-small-item__tag {
  @extend %fw-bold;
  @extend %text-uppercase;

  margin: 0 $spacing / 2 $spacing / 2 0;
  padding: $spacing / 2 $spacing;
  color: $c-gray;
  font-family: $ff-alt;
  font-size: 1.2rem;
  text-decoration: none;
  border: 1px solid $c-gray-lightest;
  border-radius: 0.5rem;
}
