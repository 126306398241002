
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.centered-block-outer {
  @include bg-color;

  & + * {
    overflow: inherit !important;
    padding-top: 0 !important;

    + section,
    + div {
      position: relative;
      z-index: 10;
    }

    + div.service-pro__options {
      background-color: #fff;
    }

    > * {
      padding-top: 8rem !important;
    }
  }

  &.is-midnight-darkest,
  &.midnight-darkest {
    background-color: $c-midnight-darker;
  }

  @include mq(m) {
    margin-bottom: -6rem;
  }
}

.centered-block {
  @extend %box-shadow;

  position: relative;
  z-index: 90; /* Check */
  margin: 0 auto;
  padding: 5.6rem 0;
  background-color: $white;
  border-radius: 3px;
  transform: translateY(-$spacing);

  .overlayer-trigger.btn {
    padding: 1rem 2.3rem;

    @include mq($until: m) {
      margin: auto !important;
    }
  }

  .centered-block__link {
    &::before {
      background-color: $c-blue-dark !important;
    }

    &:hover {
      .is-dark-blue & {
        color: $c-blue-dark !important;
      }
    }
  }

  &.no-centered-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.6rem 2.6rem;

    @include mq(m) {
      flex-direction: row;
      padding: 3.6rem 4.6rem 4.6rem;
    }
  }

  @include mq(m) {
    width: col(8);
    transform: translateY(-$spacing * 3);

    [data-template='service-pro'] & {
      width: col(12);
    }
  }
}

.centered-block__picture {
  display: flex;

  img {
    width: 25rem;
    max-width: 80px;
    height: 100%;
  }

  .icon {
    max-width: 8rem;
    fill: $c-pink-medium;
  }

  .no-centered-block & {
    img {
      width: inherit;
      max-height: 6rem;
    }

    @include mq($until: m) {
      margin-bottom: 2rem;
    }
  }
}

.centered-block__text {
  text-align: center;

  .no-centered-block & {
    strong {
      font-size: 2.4rem;

      @include mq($until: m) {
        display: block;
        // margin-bottom: -2rem; TBC : effet de bord?
      }
    }

    @include mq(m) {
      text-align: left;
    }
  }

  p {
    margin: 0;
  }

  &:not(.no-centered-block &) {
    margin: 0 inner-col(12, 1);

    @include mq(m) {
      margin: 0 inner-col(10, 1);
    }
  }
}

.centered-block__link-outer {
  display: flex;
  justify-content: center;
  padding-top: 1.6rem;

  &:not(.no-centered-block &) {
    margin: auto;
    padding: 3.6rem 1em 0;
  }
}

.centered-block__link {
  @extend %text-center;

  margin: auto;
}

.no-centered-block .centered-block__infos {
  @include mq(m) {
    margin-left: inner-col(12, 1);
  }
}
