
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































































































































































































































$sliderBP: l;

/* stylelint-disable declaration-no-important */
.card-small {
  @include bg-color;

  position: relative;
  z-index: 1;
  overflow: hidden;

  &.is-midnight-darkest {
    *:not(.card-small__items, .card-small__items *, .card-small__inner) {
      color: #fff;
    }
  }
}

.card-small__inner {
  position: relative;
  padding-top: $spacing * 2.5;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    padding-top: $spacing * 3.5;
    padding-bottom: $spacing * 5;
  }

  @include mq(xl) {
    padding-top: get-spacing(xl, l);
    padding-bottom: get-spacing(l, l);
  }
}

.card-small__promo,
[class*='card-small__promo--'] {
  position: relative;
  width: 100%;
  max-width: 44rem;
  margin: $spacing * 2 auto;
  padding: 3rem 3rem 2rem;
  border: 1px solid $c-gray-light;
  border-radius: 10px;

  @include mq(xl) {
    margin: $spacing * 4.5 auto $spacing * 6;
  }
}

.card-small__promo__label {
  @include center-x;

  top: 0;
  display: inline-block;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.4rem;
  background-color: $c-pink-medium;
  border-radius: 3px;
  transform: translate(-50%, -50%);
}

.card-small__promo__title {
  @extend %text-center;

  margin: $spacing / 4 0;
}

.card-small__description {
  width: col(8);
  margin: $spacing * 0.75 auto 3.6rem;
  font-size: 1.8rem;
  line-height: 1.5;
  text-align: center;

  @include mq(m) {
    font-size: 2rem;
  }

  @include mq(l) {
    margin-bottom: $spacing * 2.5;
    font-size: 2.4rem;
  }
}

.card-small__title {
  @extend %fw-medium;

  margin: 0 0 $spacing * 2.5;
  text-align: center;

  strong {
    @extend %fw-black;
  }

  @include mq(m) {
    margin: 0 0 $spacing * 2;
  }
}

.card-small__items {
  .icon {
    fill: #59b5c1;
    position: absolute;
    top: -205px;
    right: 164px;
    width: 86px;
    height: 86px;
    transform: rotate(8.01deg);
  }

  .blog-single & {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6rem;
    justify-content: center;
    margin: 3rem 2rem;

    @include mq(s) {
      grid-template-columns: 1fr 1fr;
      margin: 7.6rem 0;
    }
  }

  [class*='card-small--'][class*='--double'] & {
    margin: 0 -2rem;
  }

  .card-small-item {
    min-width: unset;
    max-width: unset;
    height: 100%;
    text-align: left;
  }

  @include mq(xl) {
    .blog-single & {
      grid-template-columns: 1fr 1fr 1fr;
    }

    [class*='card-small--'][class*='--double'] & {
      margin: 0 -4rem;
    }
  }
}

.card-small__items__item {
  .blog-single & {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    margin: 0 0 $spacing * 2;
    text-align: left;

    &::v-deep.card-small-item__title {
      text-align: left;
    }
  }

  [class*='card-small--'][class*='--double'] & {
    width: calc(50% - 4rem);
    max-width: none;
  }

  @include mq(xl) {
    [class*='card-small--'][class*='--double'] & {
      width: calc(50% - 6rem);
      margin: 3rem;
    }

    &.highlighted {
      grid-column: 1 / 3;
    }
  }
}

.card-small__link {
  margin-top: 2rem;
  text-align: center;

  @include mq($sliderBP) {
    margin-top: $spacing * 3.5;
  }
}

::v-deep {
  .carousel__item {
    @include mq($until: m) {
      width: 90%;
    }

    @include mq(m) {
      min-width: 30rem;
      max-width: 40rem;
    }

    @include mq(l) {
      width: 31%;
      min-width: 25%;
    }
  }
}
