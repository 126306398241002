
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        























































































$bp: l;

.duolist-card {
  position: relative;

  @include mq($bp) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.duolist-card__speedlines {
  @include center-x;

  @include mq($until: 'l') {
    display: none;
  }

  z-index: -1;
}

.duolist-card__list {
  @include mq($bp) {
    max-width: 53rem;
    margin-right: 5rem;
  }
}

.duolist-card__list__title {
  @include fluid-type(20, 36);

  margin: 0 0 1.5rem;

  @include mq($bp) {
    margin: 0 0 2.5rem;
  }
}

.duolist-card__list__items {
  margin: 0 0 3rem;
  padding: 0;

  @include mq($bp) {
    margin: 0;
  }
}

.duolist-card__list__item {
  font-family: $ff-alt;
  list-style-type: none;
  line-height: 1;

  .icon--green {
    margin-right: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.duolist-card__list__item__title {
  @extend %fw-bold;
  @include fluid-type(15, 20);

  display: flex;
  align-items: center;
}

.duolist-card__list__item__text {
  @include fluid-type(15, 18);

  margin-top: 2rem;
  padding-left: 4rem;
  font-family: $ff-default;
  line-height: 1.6;
}

.duolist-card__card {
  padding: 2rem;
  background: $white;
  border-radius: 8px;
  box-shadow: 0 10px 40px rgba($black, 0.1);

  @include mq($bp) {
    max-width: 55rem;
    padding: 4rem;
  }
}

.duolist-card__card__title {
  @include fluid-type(20, 24);

  margin: 0 0 2.5rem;
}

.duolist-card__card__link {
  margin-top: 2.5rem;
}
