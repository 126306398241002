
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        










































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.contact-item {
  padding-top: 5rem !important;

  h4 {
    margin: 0;
  }
}

.contact-item__icon {
  width: auto;
  height: 5rem;
  margin-bottom: 5px;
  fill: $c-pink-medium;
}

.contact-item__title {
  margin-bottom: 0;
}

.contact-item__text {
  p {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}
