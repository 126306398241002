
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
































.picture-block {
  &.midnight-darkest {
    background-color: $c-midnight-darker;
  }
}
.picture-outer {
  position: relative;
  width: 100%;
  margin: $spacing auto;
  text-align: center;

  @include mq(m) {
    margin-top: 0;
  }
}

.picture {
  background-color: rgba(random(255), random(255), random(255), 0.2);
}
