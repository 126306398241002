
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        












































































.contact-outer {
  @include bg-color;

  padding-bottom: 6rem;

  &.is-midnight-darkest {
    * {
      color: white;
    }
  }
}

.contact {
  position: relative;
  text-align: center;
}

.contact__title {
  margin-top: 0;
  margin-bottom: 0;
}

.lead {
  margin-bottom: 3.6rem;
}

.contact__items {
  .contact-item {
    width: 33rem;
    max-width: 33rem;
    margin: 0 auto;

    &.contact__item {
      max-width: 50rem;
    }
  }

  @include mq($until: m) {
    .contact-item + .contact-item {
      margin-top: 50px;
    }
  }

  @include mq(m) {
    display: flex;
    justify-content: center;

    .contact-item {
      margin: 0 60px;
    }
  }
}
