
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





































































































































.landing-flexible {
  ::v-deep .wysiwyg__content {
    max-width: $wrapper-width-xl;
    margin-right: auto;
    margin-left: auto;
    padding-top: get-spacing(xl, s);
    padding-inline: $wrapper-spacing-s;
    padding-bottom: get-spacing(xl, s);

    :first-child {
      margin-top: 0;
    }

    @include mq('m') {
      padding-top: get-spacing(xl, m);
      padding-inline: $wrapper-spacing-m;
      padding-bottom: get-spacing(xl, m);
    }

    @include mq('xl') {
      padding-top: get-spacing(xl, l);
      padding-inline: $wrapper-spacing-xl;
      padding-bottom: get-spacing(xl, l);
    }
  }
}

.landing-flexible__hero__anchors {
  display: flex;
  margin-top: $spacing;

  @include mq($until: m) {
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.landing-flexible__hero__anchor {
  @extend %fw-bold;

  display: inline-flex;
  align-items: center;
  color: $c-blue-dark;
  line-height: 1;
  text-decoration: none;

  &:first-child {
    @include mq(m) {
      margin-right: $spacing * 1.5;
    }
  }

  .icon {
    width: 4rem;
    height: 5rem;
    margin-right: 1rem;
    fill: $white;

    @include mq($until: m) {
      width: 3.4rem;
    }
  }

  @include mq(m) {
    margin-top: $spacing;
    margin-bottom: $spacing * 2.5;
  }

  @include mq(l) {
    margin-top: $spacing * 2;
    margin-bottom: 0;
  }
}
