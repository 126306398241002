
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        















































































































































































.faq,
[class*='faq--'] {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-outer {
  @include bg-color;

  padding-top: $spacing * 1.5;
  padding-bottom: $spacing * 1.5;

  &.is-light {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $white;
  }

  &.is-dark {
    background-color: transparent;
  }

  @include mq(m) {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 4;
  }

  @include mq(l) {
    padding-top: $spacing * 2.5;
    padding-bottom: $spacing * 5;
  }
}

.faq__picture {
  margin-bottom: 3.4rem;
  text-align: center;

  img {
    width: 27.8rem;
    height: 100%;
  }
}

.faq__title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq__title__icon {
  width: 4rem;
  height: 4rem;
  fill: $c-pink-medium;

  .is-dark & {
    fill: $white;
  }

  .is-light & {
    fill: $c-midnight-darker;
  }
}

.faq__title__headline {
  @extend %text-uppercase;
  @extend %fw-medium;

  color: $c-pink-medium;

  .is-dark & {
    color: $white;
  }

  .is-light & {
    color: $c-midnight-darker;
  }
}

.faq__listing {
  @extend %list-nostyle;

  width: 100%;
  margin-top: $spacing * 1.5;

  @include mq(l) {
    width: col(10);
  }
}

.faq__category-listing {
  width: 100%;
  margin: 0;
  padding: 0 0 1.5rem;
  background: $white;
}

.faq__category-listing__item {
  list-style-type: none;
  border-bottom: 1px solid $c-gallery;

  .faq__listing {
    margin-top: 2rem;
  }
}

.faq__category-listing__header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  background: transparent;
  border: 0;

  @include mq(s) {
    padding: 3rem 3rem 3rem 0;
  }
}

.faq__category-listing__header__icon {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin-right: 1.5rem;

  svg {
    width: 100%;
    height: 100%;
    fill: $c-pink-medium;
  }

  @include mq(s) {
    margin-right: 2.5rem;
  }
}

.faq__category-listing__header__state {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;
  background-color: $black;
  border-radius: 50%;

  &::before,
  &::after {
    @include center-xy;

    content: '';
    z-index: 2;
    width: 60%;
    height: 2px;
    background-color: $white;
    transition: transform 0.25s;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .faq__category-listing__item.is-open & {
    &::after {
      transform: translate(-50%, -50%) rotate(0);
    }
  }
}

.faq__category-listing__wrapper {
  overflow: hidden;
  height: 0;
}

.faq__item {
  & + & {
    margin-top: $spacing;
  }
}

.faq__seemore {
  margin-top: 4rem;
  color: $c-pink-medium;
  font-weight: 500;

  .is-dark & {
    color: $white;
  }

  .is-light & {
    color: $c-midnight-darker;
  }
}

.faq__afterText {
  margin-top: 4rem;
}

.faq__btn {
  margin-top: 50px;
}
