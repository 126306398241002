
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































.duo-outer {
  @include bg-color;
}

.duo,
[class*='duo--'] {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    display: flex;
  }
}

.duo__intro {
  @include mq(m) {
    width: col(5);
  }
}

.duo__intro__icon {
  fill: $c-pink-medium;
}

.duo__intro__title {
  @extend %fw-medium;

  margin-top: 0;
  padding-right: 5rem;
}

.duo__content {
  @include mq(m) {
    width: col(7);
  }
}

.duo__link {
  margin-top: $spacing;
}
