
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
























































































.duo-speedlines,
[class*='duo-speedlines--'] {
  position: relative;
  overflow: hidden;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-bottom: $spacing * 4;
  }
}

.duo-speedlines__speedlines-outer {
  margin: 0 auto;

  @include mq(m) {
    position: absolute;
    z-index: -1;
    top: -10%;
    right: -20%;
    width: 70%;
    height: 100%;
  }

  @include mq(l) {
    top: 20%;
    right: 0%;
    width: 50%;
  }

  @include mq(xl) {
    top: 0;
  }
}

.duo-speedlines__speedlines {
  width: 100%;

  @include mq($until: l) {
    position: relative;
    height: 45rem;
  }
}

.duo-speedlines__content {
  padding: 0 $wrapper-spacing-s;

  @include mq(m) {
    padding: $spacing * 2 $wrapper-spacing-s 0;
  }

  @include mq(m) {
    width: col(6);
    padding: 0;
  }

  @include mq(l) {
    padding: $spacing * 3 0;
  }

  @include mq(xl) {
    width: col(5);
  }
}

.duo-speedlines__content__switcher {
  margin-bottom: $spacing * 1.5;

  .switcher__option {
    padding: $spacing $spacing * 1.5;
  }

  .switcher__option__label {
    @extend %fw-medium;

    color: $c-blue-dark;
    font-size: 1.8rem;
    text-transform: none;
  }

  @include mq(m) {
    margin-bottom: $spacing * 2;
  }
}

.duo-speedlines__content__title {
  margin: 0 0 $spacing;

  strong {
    @extend %fw-black;
  }
}

.duo-speedlines__content__excerpt {
  margin-bottom: 4rem;
}

.duo-speedlines__content__description {
  @extend %fw-light;

  height: 10rem;
  margin: 0;
  font-size: 1.6rem;

  @include mq(m) {
    font-size: 1.8rem;
  }
}

.duo-speedlines__content__subtitle {
  margin-bottom: $spacing;

  @include mq(m) {
    margin-bottom: $spacing * 1.5;
  }
}
