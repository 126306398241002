
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































.section-wysiwyg-outer {
  @include bg-color;

  &.is-midnight-darkest,
  &.midnight-darkest {
    background-color: $c-midnight-darker;

    .section-wysiwyg * {
      color: white;
    }
  }
}
